


























import { Component, Vue } from "vue-property-decorator";
import { app_routes } from "@/router/app_routes";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";

@Component({
  components: { MaterialIcon },
})
export default class EntryPage extends Vue {
  public readonly appRoutes = app_routes;
}
