import { render, staticRenderFns } from "./EntryPage.vue?vue&type=template&id=28b0c1ef&"
import script from "./EntryPage.vue?vue&type=script&lang=ts&"
export * from "./EntryPage.vue?vue&type=script&lang=ts&"
import style0 from "./EntryPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports